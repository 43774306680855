<template>
  <div>
    <!-- hidden input for upload file -->
    <input
      ref="inputRef"
      :name="fieldName"
      :multiple="multiple"
      class="hidden"
      type="file"
      :accept="allowExtensions"
      @change="onFileSelect"
      @blur="handleBlur"
    />
    <slot :triggerUpload="triggerUpload"></slot>
    <st-error-message v-if="showErr" :name="fieldName" :showError="showFieldError" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, toRefs } from 'vue';

import StErrorMessage from '@/components/validation/st-error-message.vue';
import useValidation from '@/composables/useValidation';
import type { InputFileProps } from '@/types/common/form.type';
import { convertFileToBase64 } from '@/utils/file.util';

const inputRef = ref<HTMLInputElement>();

const props = withDefaults(defineProps<InputFileProps>(), {
  multiple: false,
  base64: true,
  allowExtensions: '',
  showErr: true
});

const { rules } = toRefs(props);

const emits = defineEmits<{
  fileSelect: [v: File[]];
  fileSelectAsBlob: [v: Blob[]];
  errors: [err: any];
}>();

const fieldName = computed(() => props.name ?? '');

const {
  setValue,
  handleBlur,
  handleChange,
  validate,
  meta,
  showError: showFieldError
} = useValidation<string>({
  fieldName: fieldName.value,
  showPopupErrorOnSubmitting: false,
  rules
});

const triggerUpload = () => {
  inputRef.value!.value = '';
  inputRef.value?.click();
};

const onFileSelect = async (e: Event) => {
  const files = (e.target as HTMLInputElement).files ?? [];
  if (!files || files.length === 0) {
    return;
  }
  handleChange(e);
  await validate();

  if (!meta.valid) {
    setValue('', false);
    return;
  }
  if (props.base64) {
    const base64File = await convertFileToBase64(files[0]);
    setValue(base64File);
  } else {
    setValue(files[0].name);
  }
  emits('fileSelect', files as File[]);
};
</script>
